var c_cache=[];
function RunAjaxJS(b,c){var d=new Date,e=!1,d=d.getTime(),f=/<script.*?>(.|[\r\n])*?<\/script>/ig,g=f.exec(c);if(null!=g){for(var h=Array(g.shift()),e=!0;g;)g=f.exec(c),null!=g&&h.push(g.shift());for(f=0;f<h.length;f++)c=c.replace(h[f],'<span id="'+d+f+'" style="display:none;"></span>')}$("#"+b).html(c);if(e){e=/<script.*?>((.|[\r\n])*?)<\/script>/ig;for(f=0;f<h.length;f++){var j=document.getElementById(d+""+f),g=j.parentNode;g.removeChild(j);e.lastIndex=0;j=e.exec(h[f]);g=g.appendChild(document.createElement("script"));g.text=
j[1];j=h[f].substring(h[f].indexOf(" ",0),h[f].indexOf(">",0)).split(" ");if(1<j.length)for(var l=0;l<j.length;l++)if(0<j[l].length){var k=j[l].split("=");k[1]=k[1].substr(1,k[1].length-2);g.setAttribute(k[0],k[1])}}}}
function IPMenu(b,c,d,e){var f=[];f[0]='<a href="https://www.nic.ru/whois/?ip='+b+'" target="_blank">'+c+"</a>";f[1]='<a href="'+dle_root+dle_admin+"?mod=iptools&ip="+b+'" target="_blank">'+d+"</a>";f[2]='<a href="'+dle_root+dle_admin+"?mod=blockip&ip="+b+'" target="_blank">'+e+"</a>";return f}
function ajax_save_for_edit(b,c){var d={};"1"==quick_wysiwyg&&submit_all_data();$.each($("#ajaxnews"+b).serializeArray(),function(b,c){-1!=c.name.indexOf("xfield")&&(d[c.name]=c.value)});document.getElementById("allow_br_"+b).checked&&(d.allow_br=1);d.news_txt=$("#dleeditnews"+b).val();d.full_txt=$("#dleeditfullnews"+b).val();d.title=$("#edit-title-"+b).val();d.reason=$("#edit-reason-"+b).val();d.id=b;d.field=c;d.action="save";ShowLoading("");$.post(dle_root+"engine/ajax/editnews.php",d,function(b){HideLoading("");
"ok"!=b?DLEalert(b,dle_info):($("#dlepopup-news-edit").dialog("close"),DLEconfirm(dle_save_ok,dle_confirm,function(){location.reload(!0)}))});return!1}
function ajax_prep_for_edit(b,c){for(var d=0,e=c_cache.length;d<e;d++)d in c_cache&&(c_cache[d]||""!=c_cache[d])&&ajax_cancel_comm_edit(d);ShowLoading("");$.get(dle_root+"engine/ajax/editnews.php",{id:b,field:c,action:"edit"},function(d){HideLoading("");var e="none";$("#modal-overlay").remove();$("body").prepend('<div id="modal-overlay" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: #666666; opacity: .40;filter:Alpha(Opacity=40); z-index: 999; display:none;"></div>');$("#modal-overlay").css({filter:"alpha(opacity=40)"}).fadeIn();
var h={};h[dle_act_lang[3]]=function(){$(this).dialog("close")};h[dle_act_lang[4]]=function(){ajax_save_for_edit(b,c)};$("#dlepopup-news-edit").remove();$("body").prepend("<div id='dlepopup-news-edit' class='dlepopupnewsedit' title='"+menu_short+"' style='display:none'></div>");$(".dlepopupnewsedit").html("");$("#dlepopup-news-edit").dialog({autoOpen:!0,width:"800",height:500,buttons:h,dialogClass:"modalfixed",dragStart:function(){e=$(".modalfixed").css("box-shadow");$(".modalfixed").css("box-shadow",
"none")},dragStop:function(){$(".modalfixed").css("box-shadow",e)},close:function(){$(this).dialog("destroy");$("#modal-overlay").fadeOut(function(){$("#modal-overlay").remove()})}});830<$(window).width()&&530<$(window).height()&&($(".modalfixed.ui-dialog").css({position:"fixed"}),$("#dlepopup-news-edit").dialog("option","position",["0","0"]));RunAjaxJS("dlepopup-news-edit",d)});return!1}
function ajax_comm_edit(b,c){for(var d=0,e=c_cache.length;d<e;d++)d in c_cache&&""!=c_cache[d]&&ajax_cancel_comm_edit(d);if(!c_cache[b]||""==c_cache[b])c_cache[b]=$("#comm-id-"+b).html();ShowLoading("");$.get(dle_root+"engine/ajax/editcomments.php",{id:b,area:c,action:"edit"},function(c){HideLoading("");RunAjaxJS("comm-id-"+b,c);setTimeout(function(){$("html:not(:animated)"+(!$.browser.opera?",body:not(:animated)":"")).animate({scrollTop:$("#comm-id-"+b).offset().top-70},700)},100)});return!1}
function ajax_cancel_comm_edit(b){""!=c_cache[b]&&$("#comm-id-"+b).html(c_cache[b]);c_cache[b]="";return!1}function ajax_save_comm_edit(b,c){"1"==dle_wysiwyg&&submit_all_data();var d=$("#dleeditcomments"+b).val();ShowLoading("");$.post(dle_root+"engine/ajax/editcomments.php",{id:b,comm_txt:d,area:c,action:"save"},function(c){HideLoading("");c_cache[b]="";$("#comm-id-"+b).html(c)});return!1}
function DeleteComments(b,c){DLEconfirm(dle_del_agree,dle_confirm,function(){ShowLoading("");$.get(dle_root+"engine/ajax/deletecomments.php",{id:b,dle_allow_hash:c},function(b){HideLoading("");b=parseInt(b);isNaN(b)||($("html"+(!$.browser.opera?",body":"")).animate({scrollTop:$("#comment-id-"+b).offset().top-70},700),setTimeout(function(){$("#comment-id-"+b).hide("blind",{},1400)},700))})})}
function MarkSpam(b,c){DLEconfirm(dle_spam_agree,dle_confirm,function(){ShowLoading("");$.get(dle_root+"engine/ajax/adminfunction.php",{id:b,action:"commentsspam",user_hash:c},function(b){HideLoading("");"error"!=b&&DLEconfirm(b,dle_confirm,function(){location.reload(!0)})})})}function doFavorites(b,c){ShowLoading("");$.get(dle_root+"engine/ajax/favorites.php",{fav_id:b,action:c,skin:dle_skin},function(c){HideLoading("");$("#fav-id-"+b).html(c)});return!1}
function CheckLogin(){var b=document.getElementById("name").value;ShowLoading("");$.post(dle_root+"engine/ajax/registration.php",{name:b},function(b){HideLoading("");$("#result-registration").html(b)});return!1}
function doCalendar(b,c,d){ShowLoading("");$.get(dle_root+"engine/ajax/calendar.php",{month:b,year:c},function(b){HideLoading("");"left"==d?$("#calendar-layer").hide("slide",{direction:"left"},500).html(b).show("slide",{direction:"right"},500):$("#calendar-layer").hide("slide",{direction:"right"},500).html(b).show("slide",{direction:"left"},500)})}
function doRate(b,c){ShowLoading("");$.get(dle_root+"engine/ajax/rating.php",{go_rate:b,news_id:c,skin:dle_skin},function(b){HideLoading("");if(b.success){var e=b.rating,e=e.replace(/&lt;/g,"<"),e=e.replace(/&gt;/g,">"),e=e.replace(/&amp;/g,"&");$("#ratig-layer-"+c).html(e);$("#vote-num-id-"+c).html(b.votenum)}},"json")}
function doAddComments(){var b=document.getElementById("dle-comments-form");if("1"==dle_wysiwyg||"2"==dle_wysiwyg){"1"==dle_wysiwyg?submit_all_data():document.getElementById("comments").value=$("#comments").html();var c="wysiwyg"}else c="";if(""==b.comments.value||""==b.name.value)return DLEalert(dle_req_field,dle_info),!1;var d=b.question_answer?b.question_answer.value:"",e=b.sec_code?b.sec_code.value:"";if(b.recaptcha_response_field)var f=Recaptcha.get_response(),g=Recaptcha.get_challenge();else g=
f="";var h=b.allow_subscribe?!0==b.allow_subscribe.checked?"1":"0":"0";ShowLoading("");$.post(dle_root+"engine/ajax/addcomments.php",{post_id:b.post_id.value,comments:b.comments.value,name:b.name.value,mail:b.mail.value,editor_mode:c,skin:dle_skin,sec_code:e,question_answer:d,recaptcha_response_field:f,recaptcha_challenge_field:g,allow_subscribe:h},function(c){b.sec_code&&(b.sec_code.value="",reload());HideLoading("");RunAjaxJS("dle-ajax-comments",c);"error"!=c&&document.getElementById("blind-animation")&&
($("html"+(!$.browser.opera?",body":"")).animate({scrollTop:$("#dle-ajax-comments").offset().top-70},1100),setTimeout(function(){$("#blind-animation").show("blind",{},1500)},1100))})}
function CommentsPage(b,c){ShowLoading("");$.get(dle_root+"engine/ajax/comments.php",{cstart:b,news_id:c,skin:dle_skin},function(d){HideLoading("");!isNaN(b)&&!isNaN(c)&&($("#dle-comm-link").unbind("click"),$("#dle-comm-link").bind("click",function(){CommentsPage(b,c);return!1}));scroll(0,$("#dle-comments-list").offset().top-70);$("#dle-comments-list").html(d.comments);$(".dle-comments-navigation").html(d.navigation)},"json");return!1}
function dle_copy_quote(b){dle_txt="";window.getSelection?dle_txt=window.getSelection():document.selection&&(dle_txt=document.selection.createRange().text);""!=dle_txt&&(dle_txt="[quote="+b+"]"+dle_txt+"[/quote]")}
function dle_ins(b){if(!document.getElementById("dle-comments-form"))return!1;var c=document.getElementById("dle-comments-form").comments,d="";""!=dle_txt?("0"==dle_wysiwyg?(c.value+=dle_txt+"\n",c.focus()):(d=dle_txt+"<br />","1"==dle_wysiwyg?(oUtil.obj.focus(),oUtil.obj.insertHTML(d)):tinyMCE.execInstanceCommand("comments","mceInsertContent",!1,d,!0)),setTimeout(function(){$("html:not(:animated)"+(!$.browser.opera?",body:not(:animated)":"")).animate({scrollTop:$("#dle-comments-form").offset().top-
70},700)},100)):(ShowLoading(""),$.get(dle_root+"engine/ajax/quote.php",{id:b},function(b){HideLoading("");"0"==dle_wysiwyg?(c.value+=b+"\n",c.focus()):(d=b+"<br />","1"==dle_wysiwyg?(oUtil.obj.focus(),oUtil.obj.insertHTML(d)):tinyMCE.execInstanceCommand("comments","mceInsertContent",!1,d,!0));setTimeout(function(){$("html:not(:animated)"+(!$.browser.opera?",body:not(:animated)":"")).animate({scrollTop:$("#dle-comments-form").offset().top-70},700)},100)}));return!1}
function ShowOrHide(b){var c=$("#"+b);b=document.getElementById("image-"+b)?document.getElementById("image-"+b):null;var d=1E3*(c.height()/200);3E3<d&&(d=3E3);250>d&&(d=250);"none"==c.css("display")?(c.show("blind",{},d),b&&(b.src=dle_root+"templates/"+dle_skin+"/dleimages/spoiler-minus.gif")):(2E3<d&&(d=2E3),c.hide("blind",{},d),b&&(b.src=dle_root+"templates/"+dle_skin+"/dleimages/spoiler-plus.gif"))}
function ckeck_uncheck_all(){for(var b=document.pmlist,c=0;c<b.elements.length;c++){var d=b.elements[c];"checkbox"==d.type&&(d.checked=!0==b.master_box.checked?!1:!0)}b.master_box.checked=!0==b.master_box.checked?!1:!0}function confirmDelete(b){DLEconfirm(dle_del_agree,dle_confirm,function(){document.location=b})}function setNewField(b,c){b!=selField&&(fombj=c,selField=b)}
function dle_news_delete(b){var c={};c[dle_act_lang[1]]=function(){$(this).dialog("close")};allow_dle_delete_news&&(c[dle_del_msg]=function(){$(this).dialog("close");var c={};c[dle_act_lang[3]]=function(){$(this).dialog("close")};c[dle_p_send]=function(){if(1>$("#dle-promt-text").val().length)$("#dle-promt-text").addClass("ui-state-error");else{var c=$("#dle-promt-text").val();$(this).dialog("close");$("#dlepopup").remove();$.post(dle_root+"engine/ajax/message.php",{id:b,text:c},function(c){"ok"==
c?document.location=dle_root+"index.php?do=deletenews&id="+b+"&hash="+dle_login_hash:DLEalert("Send Error",dle_info)})}};$("#dlepopup").remove();$("body").append("<div id='dlepopup' title='"+dle_notice+"' style='display:none'><br />"+dle_p_text+"<br /><br /><textarea name='dle-promt-text' id='dle-promt-text' class='ui-widget-content ui-corner-all' style='width:97%;height:100px; padding: .4em;'></textarea></div>");$("#dlepopup").dialog({autoOpen:!0,width:500,dialogClass:"modalfixed",buttons:c});$(".modalfixed.ui-dialog").css({position:"fixed"});
$("#dlepopup").dialog("option","position",["0","0"])});c[dle_act_lang[0]]=function(){$(this).dialog("close");document.location=dle_root+"index.php?do=deletenews&id="+b+"&hash="+dle_login_hash};$("#dlepopup").remove();$("body").append("<div id='dlepopup' title='"+dle_confirm+"' style='display:none'><br /><div id='dlepopupmessage'>"+dle_del_agree+"</div></div>");$("#dlepopup").dialog({autoOpen:!0,width:500,dialogClass:"modalfixed",buttons:c});$(".modalfixed.ui-dialog").css({position:"fixed"});$("#dlepopup").dialog("option",
"position",["0","0"])}
function MenuNewsBuild(b,c){var d=[];d[0]="<a onclick=\"ajax_prep_for_edit('"+b+"', '"+c+'\'); return false;" href="#">'+menu_short+"</a>";""!=dle_admin&&(d[1]='<a href="'+dle_root+dle_admin+"?mod=editnews&action=editnews&id="+b+'" target="_blank">'+menu_full+"</a>");allow_dle_delete_news&&(d[2]="<a onclick=\"sendNotice ('"+b+'\'); return false;" href="#">'+dle_notice+"</a>",d[3]="<a onclick=\"dle_news_delete ('"+b+'\'); return false;" href="#">'+dle_del_news+"</a>");return d}
function sendNotice(b){var c={};c[dle_act_lang[3]]=function(){$(this).dialog("close")};c[dle_p_send]=function(){if(1>$("#dle-promt-text").val().length)$("#dle-promt-text").addClass("ui-state-error");else{var c=$("#dle-promt-text").val();$(this).dialog("close");$("#dlepopup").remove();$.post(dle_root+"engine/ajax/message.php",{id:b,text:c,allowdelete:"no"},function(b){"ok"==b&&DLEalert(dle_p_send_ok,dle_info)})}};$("#dlepopup").remove();$("body").append("<div id='dlepopup' title='"+dle_notice+"' style='display:none'><br />"+
dle_p_text+"<br /><br /><textarea name='dle-promt-text' id='dle-promt-text' class='ui-widget-content ui-corner-all' style='width:97%;height:100px; padding: .4em;'></textarea></div>");$("#dlepopup").dialog({autoOpen:!0,width:500,dialogClass:"modalfixed",buttons:c});$(".modalfixed.ui-dialog").css({position:"fixed"});$("#dlepopup").dialog("option","position",["0","0"])}
function AddComplaint(b,c){var d={};d[dle_act_lang[3]]=function(){$(this).dialog("close")};d[dle_p_send]=function(){if(1>$("#dle-promt-text").val().length)$("#dle-promt-text").addClass("ui-state-error");else{var d=$("#dle-promt-text").val();$(this).dialog("close");$("#dlepopup").remove();$.post(dle_root+"engine/ajax/complaint.php",{id:b,text:d,action:c},function(b){"ok"==b?DLEalert(dle_p_send_ok,dle_info):DLEalert(b,dle_info)})}};$("#dlepopup").remove();$("body").append("<div id='dlepopup' title='"+
dle_complaint+"' style='display:none'><br /><textarea name='dle-promt-text' id='dle-promt-text' class='ui-widget-content ui-corner-all' style='width:97%;height:100px; padding: .4em;'></textarea></div>");$("#dlepopup").dialog({autoOpen:!0,width:500,dialogClass:"modalfixed",buttons:d});$(".modalfixed.ui-dialog").css({position:"fixed"});$("#dlepopup").dialog("option","position",["0","0"])}
function DLEalert(b,c){$("#dlepopup").remove();$("body").append("<div id='dlepopup' title='"+c+"' style='display:none'><br />"+b+"</div>");$("#dlepopup").dialog({autoOpen:!0,width:470,dialogClass:"modalfixed",buttons:{Ok:function(){$(this).dialog("close");$("#dlepopup").remove()}}});$(".modalfixed.ui-dialog").css({position:"fixed"});$("#dlepopup").dialog("option","position",["0","0"])}
function DLEconfirm(b,c,d){var e={};e[dle_act_lang[1]]=function(){$(this).dialog("close");$("#dlepopup").remove()};e[dle_act_lang[0]]=function(){$(this).dialog("close");$("#dlepopup").remove();d&&d()};$("#dlepopup").remove();$("body").append("<div id='dlepopup' title='"+c+"' style='display:none'><br />"+b+"</div>");$("#dlepopup").dialog({autoOpen:!0,width:500,dialogClass:"modalfixed",buttons:e});$(".modalfixed.ui-dialog").css({position:"fixed"});$("#dlepopup").dialog("option","position",["0","0"])}
function DLEprompt(b,c,d,e,f){var g={};g[dle_act_lang[3]]=function(){$(this).dialog("close")};g[dle_act_lang[2]]=function(){if(!f&&1>$("#dle-promt-text").val().length)$("#dle-promt-text").addClass("ui-state-error");else{var b=$("#dle-promt-text").val();$(this).dialog("close");$("#dlepopup").remove();e&&e(b)}};$("#dlepopup").remove();$("body").append("<div id='dlepopup' title='"+d+"' style='display:none'><br />"+b+"<br /><br /><input type='text' name='dle-promt-text' id='dle-promt-text' class='ui-widget-content ui-corner-all' style='width:97%; padding: .4em;' value='"+
c+"'/></div>");$("#dlepopup").dialog({autoOpen:!0,width:500,dialogClass:"modalfixed",buttons:g});$(".modalfixed.ui-dialog").css({position:"fixed"});$("#dlepopup").dialog("option","position",["0","0"]);0<c.length?$("#dle-promt-text").select().focus():$("#dle-promt-text").focus()}var dle_user_profile="",dle_user_profile_link="";
function ShowPopupProfile(b,c){var d={};d[menu_profile]=function(){document.location=dle_user_profile_link};5!=dle_group&&(d[menu_send]=function(){document.location=dle_root+"index.php?do=pm&doaction=newpm&username="+dle_user_profile});1==c&&(d[menu_uedit]=function(){$(this).dialog("close");var b={};$("body").append('<div id="modal-overlay" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: #666666; opacity: .40;filter:Alpha(Opacity=40); z-index: 999; display:none;"></div>');
$("#modal-overlay").css({filter:"alpha(opacity=40)"}).fadeIn("slow");$("#dleuserpopup").remove();$("body").append("<div id='dleuserpopup' title='"+menu_uedit+"' style='display:none'></div>");b[dle_act_lang[3]]=function(){$(this).dialog("close");$("#dleuserpopup").remove()};b[dle_act_lang[4]]=function(){document.getElementById("edituserframe").contentWindow.document.getElementById("saveuserform").submit()};$("#dleuserpopup").dialog({autoOpen:!0,show:"fade",width:560,height:500,dialogClass:"modalfixed",
buttons:b,open:function(){$("#dleuserpopup").html("<iframe name='edituserframe' id='edituserframe' width='100%' height='400' src='"+dle_root+dle_admin+"?mod=editusers&action=edituser&user="+dle_user_profile+"&skin="+dle_skin+"' frameborder='0' marginwidth='0' marginheight='0' allowtransparency='true'></iframe>")},beforeClose:function(){$("#dleuserpopup").html("")},close:function(){$("#modal-overlay").fadeOut("slow",function(){$("#modal-overlay").remove()})}});830<$(window).width()&&530<$(window).height()&&
($(".modalfixed.ui-dialog").css({position:"fixed"}),$("#dleuserpopup").dialog("option","position",["0","0"]))});$("#dleprofilepopup").remove();$("body").append(b);$("#dleprofilepopup").dialog({autoOpen:!0,show:"fade",hide:"fade",buttons:d,width:500});return!1}
function ShowProfile(b,c,d){if(dle_user_profile==b&&document.getElementById("dleprofilepopup"))return $("#dleprofilepopup").dialog("open"),!1;dle_user_profile=b;dle_user_profile_link=c;ShowLoading("");$.get(dle_root+"engine/ajax/profile.php",{name:b,skin:dle_skin},function(b){HideLoading("");ShowPopupProfile(b,d)});return!1}
function FastSearch(){$("#story").attr("autocomplete","off");$("#story").blur(function(){$("#searchsuggestions").fadeOut()});$("#story").keyup(function(){var b=$(this).val();0==b.length?$("#searchsuggestions").fadeOut():dle_search_value!=b&&3<b.length&&(clearInterval(dle_search_delay),dle_search_delay=setInterval(function(){dle_do_search(b)},600))})}
function dle_do_search(b){clearInterval(dle_search_delay);$("#searchsuggestions").remove();$("body").append("<div id='searchsuggestions' style='display:none'></div>");$.post(dle_root+"engine/ajax/search.php",{query:""+b+""},function(b){$("#searchsuggestions").html(b).fadeIn().css({position:"absolute",top:0,left:0}).position({my:"left top",at:"left bottom",of:"#story",collision:"fit flip"})});dle_search_value=b}
function ShowLoading(b){b&&$("#loading-layer").html(b);b=($(window).width()-$("#loading-layer").width())/2;var c=($(window).height()-$("#loading-layer").height())/2;$("#loading-layer").css({left:b+"px",top:c+"px",position:"fixed",zIndex:"99"});$("#loading-layer").fadeTo("slow",0.6)}function HideLoading(){$("#loading-layer").fadeOut("slow")}
function ShowAllVotes(){if(document.getElementById("dlevotespopup"))return $("#dlevotespopup").dialog("open"),!1;$.ajaxSetup({cache:!1});ShowLoading("");$.get(dle_root+"engine/ajax/allvotes.php?dle_skin="+dle_skin,function(b){HideLoading("");$("#dlevotespopup").remove();$("body").append(b);$(".dlevotebutton").button();$("#dlevotespopup").dialog({autoOpen:!0,show:"fade",hide:"fade",width:600,height:150});400<$("#dlevotespopupcontent").height()&&$("#dlevotespopupcontent").height(400);$("#dlevotespopup").dialog("option",
"height",$("#dlevotespopupcontent").height()+60);$("#dlevotespopup").dialog("option","position","center")});return!1}function fast_vote(b){var c=$("#vote_"+b+" input:radio[name=vote_check]:checked").val();ShowLoading("");$.get(dle_root+"engine/ajax/vote.php",{vote_id:b,vote_action:"vote",vote_mode:"fast_vote",vote_check:c,vote_skin:dle_skin},function(c){HideLoading("");$("#dle-vote_list-"+b).fadeOut(500,function(){$(this).html(c);$(this).fadeIn(500)})});return!1}
function AddIgnorePM(b,c){DLEconfirm(c,dle_confirm,function(){ShowLoading("");$.get(dle_root+"engine/ajax/pm.php",{id:b,action:"add_ignore",skin:dle_skin},function(b){HideLoading("");DLEalert(b,dle_info);return!1})})}function DelIgnorePM(b,c){DLEconfirm(c,dle_confirm,function(){ShowLoading("");$.get(dle_root+"engine/ajax/pm.php",{id:b,action:"del_ignore",skin:dle_skin},function(c){HideLoading("");$("#dle-ignore-list-"+b).html("");DLEalert(c,dle_info);return!1})})}
function media_upload(b,c,d,e){var f=(new Date).getTime(),g="none";$("#mediaupload").remove();$("body").append("<div id='mediaupload' title='"+text_upload+"' style='display:none'></div>");$("#mediaupload").dialog({autoOpen:!0,width:680,height:600,dialogClass:"modalfixed",open:function(){$("#mediaupload").html("<iframe name='mediauploadframe' id='mediauploadframe' width='100%' height='550' src='"+dle_root+"engine/ajax/upload.php?area="+b+"&author="+c+"&news_id="+d+"&wysiwyg="+e+"&skin="+dle_skin+"&rndval="+
f+"' frameborder='0' marginwidth='0' marginheight='0' allowtransparency='true'></iframe>");$(".ui-dialog").draggable("option","containment","")},dragStart:function(){g=$(".modalfixed").css("box-shadow");$(".modalfixed").fadeTo(0,0.6).css("box-shadow","none");$("#mediaupload").hide()},dragStop:function(){$(".modalfixed").fadeTo(0,1).css("box-shadow",g);$("#mediaupload").show()},beforeClose:function(){$("#mediaupload").html("")}});830<$(window).width()&&530<$(window).height()&&($(".modalfixed.ui-dialog").css({position:"fixed"}),
$("#mediaupload").dialog("option","position",["0","0"]));return!1}
function dropdownmenu(b,c,d,e){window.event?event.cancelBubble=!0:c.stopPropagation&&c.stopPropagation();c=$("#dropmenudiv");if(c.is(":visible"))return clearhidemenu(),c.fadeOut("fast"),!1;c.remove();$("body").append('<div id="dropmenudiv" style="display:none;position:absolute;z-index:100;width:165px;"></div>');c=$("#dropmenudiv");c.html(d.join(""));e&&c.width(e);d=$(document).width()-30;e=$(b).offset();d-e.left<c.width()&&(e.left-=c.width()-$(b).width());c.css({left:e.left+"px",top:e.top+$(b).height()+
"px"});c.fadeTo("fast",0.9);c.mouseenter(function(){clearhidemenu()}).mouseleave(function(){delayhidemenu()});$(document).one("click",function(){hidemenu()});return!1}function hidemenu(){$("#dropmenudiv").fadeOut("fast")}function delayhidemenu(){delayhide=setTimeout("hidemenu()",1E3)}function clearhidemenu(){"undefined"!=typeof delayhide&&clearTimeout(delayhide)}
function updateSharePopupContent(){$("#share-popup .link a").html(location+"<ins></ins>").attr("href",location);var b=encodeURI($("#news-title").text()),c=encodeURIComponent(location);$("#share-popup .social .tw").attr("href","http://twitter.com/share?url="+c+"&text="+b);$("#share-popup .social .tw").attr("onclick","window.open('http://twitter.com/share?url="+c+"&text="+b+"','plusshare','width=650,height=400,resizable=1,scrollbars=1,left='+(screen.availWidth/2-325)+',top='+(screen.availHeight/2-200)+'');return false;");
$("#share-popup .social .fb").attr("href","http://www.facebook.com/share.php?u="+c);$("#share-popup .social .fb").attr("onclick","window.open('http://www.facebook.com/share.php?u="+c+"','plusshare','width=650,height=400,resizable=1,scrollbars=1,left='+(screen.availWidth/2-325)+',top='+(screen.availHeight/2-200)+'');return false;");$("#share-popup .social .vk").attr("href","http://vk.com/share.php?url="+c);$("#share-popup .social .vk").attr("onclick","window.open('http://vk.com/share.php?url="+c+"','plusshare','width=650,height=400,resizable=1,scrollbars=1,left='+(screen.availWidth/2-325)+',top='+(screen.availHeight/2-200)+'');return false;");
$("#share-popup .social .gp").attr("href","https://plus.google.com/share?url="+c);$("#share-popup .social .gp").attr("onclick","window.open('https://plus.google.com/share?url="+c+"','plusshare','width=650,height=500,resizable=1,scrollbars=1,left='+(screen.availWidth/2-325)+',top='+(screen.availHeight/2-250)+'');return false;")}
function showSharePopup(b){a=$(b).offset();$("#share-popup").fadeTo("fast",1);$("#share-popup").addClass("show").css({left:a.left+5+$("#share-popup").width()>=$(window).width()?$(window).width()-$("#share-popup").width()-15:a.left+5,top:a.top-$("#share-popup").height()-25})}function hideSharePopup(){$("#share-popup").removeClass("show");$("#share-popup").hide()}
jQuery(function(b){b(document).keydown(function(c){if(13==c.which&&c.ctrlKey){if(window.getSelection)var d=window.getSelection();else document.getSelection?d=document.getSelection():document.selection&&(d=document.selection.createRange().text);""!=d&&(255<d.toString().length?b.browser.mozilla?alert(dle_big_text):DLEalert(dle_big_text,dle_info):(c={},c[dle_act_lang[3]]=function(){b(this).dialog("close")},c[dle_p_send]=function(){if(1>b("#dle-promt-text").val().length)b("#dle-promt-text").addClass("ui-state-error");
else{var c=b("#dle-promt-text").val(),d=b("#orfom").text();b(this).dialog("close");b("#dlepopup").remove();b.post(dle_root+"engine/ajax/complaint.php",{seltext:d,text:c,action:"orfo",url:window.location.href},function(c){"ok"==c?b.browser.mozilla?alert(dle_p_send_ok):DLEalert(dle_p_send_ok,dle_info):b.browser.mozilla?alert(c):DLEalert(c,dle_info)})}},b("#dlepopup").remove(),b("body").append("<div id='dlepopup' title='"+dle_orfo_title+"' style='display:none'><br /><textarea name='dle-promt-text' id='dle-promt-text' class='ui-widget-content ui-corner-all' style='width:97%;height:80px; padding: .4em;'></textarea><div id='orfom' style='display:none'>"+
d+"</div></div>"),b("#dlepopup").dialog({autoOpen:!0,width:600,dialogClass:"modalfixed",buttons:c}),b(".modalfixed.ui-dialog").css({position:"fixed"}),b("#dlepopup").dialog("option","position",["0","0"])))}})});